import detectEthereumProvider from "@metamask/detect-provider";
import { store } from "../store";

export class web3Interface {
  provider: any;

  constructor() {}

  connect = async (): Promise<any> => {
    this.provider = await detectEthereumProvider();

    if (this.provider) {
      // @ts-ignore
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      console.log(account);

      store.commit("setConnected", true);
      store.commit("setWalletAddress", account[0]);

      // Here we will interface with vuex for state changes.
      this.provider.on("connect", (accounts: string[]) => {
        console.log(accounts);
      });
      // Subscribe to accounts change
      this.provider.on("accountsChanged", (accounts: string[]) => {
        store.commit("setWalletAddress", accounts[0]);
      });

      // Subscribe to chainId change
      this.provider.on("chainChanged", (chainId: number) => {
        console.log(chainId);
      });

      // Subscribe to provider disconnection
      this.provider.on(
        "disconnect",
        (error: { code: number; message: string }) => {
          console.log(error);
        }
      );
    }
  };
}
