
import { computed } from "vue";
import { useStore } from "@/store";
import { ref, watchEffect } from "vue";

export default {
  setup() {
    const store = useStore();

    let buttonString = ref("Connect");
    let showMobileNav = ref(false);
    let moonActive = ref(false);

    const moons = computed(() => store.getters.getShowMoons);
    const connected = computed(() => store.getters.getConnected);
    const wallet = computed(() => store.getters.getWallet);

    if (localStorage.getItem('isConnected') && !connected.value) {
      // reconnect web3
      connectWeb3();
    }

    watchEffect(() => {
      connected.value
        ? (buttonString.value = shortenAddress(wallet.value))
        : (buttonString.value = "Connect");
      moons.value ? (moonActive.value = true) : (moonActive.value = false);
    });

    const shortenAddress = (address: string, chars = 3): string => {
      return `${address.slice(0, chars)}...${address.slice(-chars)}`;
    };

    function showMoons() {
      store.commit("showMoons", true);
    }
    function showMoonsMobile() {
      store.commit("showMoons", true);
      moonActive.value = true;
      setTimeout(() => {
        showMobileNav.value = false;
      }, 500);
    }

    async function connectWeb3() {
      if (connected.value) return;
      await store.dispatch("connectWeb3")
    }

    const mobileNavActive = () => {
      showMobileNav.value = true;
    };
    const mobileNavInactive = () => {
      showMobileNav.value = false;
    };

    return {
      connected,
      connectWeb3,
      buttonString,
      moonActive,
      showMoons,
      showMobileNav,
      mobileNavActive,
      mobileNavInactive,
      showMoonsMobile,
    };
  },
};
