import * as THREE from "three";
//@ts-ignore
import * as POSTPROCESSING from "postprocessing";

let scene: any, camera: any, renderer: any, composer: any;

const cloudParticles: any[] = [];

export const onWindowResize = () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
};

export const render = () => {
  try {
    cloudParticles.forEach((p) => {
      p.rotation.z -= 0.001;
    });
    composer.render(0.1);
    requestAnimationFrame(render);
  } catch (e) {
    console.log(e);
  }
};
export const init = () => {
  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    1,
    1000
  );
  camera.position.z = 1;
  camera.rotation.x = 1.16;
  camera.rotation.y = -0.12;
  camera.rotation.z = 0.27;
  // lilly
  const ambient = new THREE.AmbientLight(0x8360cf);
  scene.add(ambient);
  renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  // lilly
  scene.fog = new THREE.FogExp2(0x251c33, 0.001);
  renderer.setClearColor(scene.fog.color);
  document.body.appendChild(renderer.domElement);

  const loader = new THREE.TextureLoader();
  let cloudGeo;
  let cloudMaterial;
  loader.load(
    "smoke.png",
    (texture) => {
      //texture is loaded
      cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
      cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
      });
      for (let p = 0; p < 50; p++) {
        const cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
        cloud.position.set(
          Math.random() * 800 - 400,
          500,
          Math.random() * 500 - 500
        );
        cloud.rotation.x = 1.16;
        cloud.rotation.y = -0.12;
        cloud.rotation.z = Math.random() * 2 * Math.PI;
        cloud.material.opacity = 0.55;
        cloudParticles.push(cloud);
        try {
          scene.add(cloud);
        } catch (e) {
          console.log(e);
        }
      }
    },
    (progress) => {
      console.log(progress);
    },
    (error) => {
      console.log(error);
    }
  );

  // lilly
  const directionalLight = new THREE.DirectionalLight(0xa069c9);
  directionalLight.position.set(0, 0, 1);
  scene.add(directionalLight);

  const orangeLight = new THREE.PointLight(0x7f5bcc, 50, 450, 1.7);
  orangeLight.position.set(200, 300, 100);
  scene.add(orangeLight);

  const redLight = new THREE.PointLight(0x641277, 50, 450, 1.7);
  redLight.position.set(100, 300, 100);
  scene.add(redLight);

  const blueLight = new THREE.PointLight(0x402662, 50, 450, 1.7);
  blueLight.position.set(300, 300, 200);
  scene.add(blueLight);

  loader.load("stars.jpg", (texture) => {
    const textureEffect = new POSTPROCESSING.TextureEffect({
      blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE,
      texture: texture,
    });
    textureEffect.blendMode.opacity.value = 0.2;

    const bloomEffect = new POSTPROCESSING.BloomEffect({
      blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE,
      kernelSize: POSTPROCESSING.KernelSize.SMALL,
      useLuminanceFilter: true,
      luminanceThreshold: 0.3,
      luminanceSmoothing: 0.75,
    });
    bloomEffect.blendMode.opacity.value = 1.5;

    const effectPass = new POSTPROCESSING.EffectPass(
      camera,
      bloomEffect,
      textureEffect
    );
    composer = new POSTPROCESSING.EffectComposer(renderer);
    composer.addPass(new POSTPROCESSING.RenderPass(scene, camera));
    composer.addPass(effectPass);

    render();
  });
};
