import { ethers } from "ethers";
//@ts-ignore
import abi from "./abi.json";

const nyxgrDeployments = {
  1: "0x358F955285681aD448352ef6b0A50CCA8E9102ef",
  42: "0x49D556Bec37E1A6fbA31d3024f8D06dB489785F0"
};

export const nyxgrContractInit = (provider: any, chain: number) => {
  // @ts-ignore
  const address = nyxgrDeployments[chain]; // refactor
  return new ethers.Contract(address, abi, provider);
};
