import { ethers } from "ethers";
//@ts-ignore
import abi from "./abi.json";

const nyxtDeployments = {
  1: "0x118b552725e1892137740cB4d29390D952709639",
  42: "0x75494AfB5FFDfA00befFA8C58aA613d0d7B818B2",
};

export const nyxtContractInit = (provider: any, chain: number) => {
  // @ts-ignore
  const address = nyxtDeployments[chain]; // refactor
  return new ethers.Contract(address, abi, provider);
};
