import { InjectionKey, markRaw } from "vue";
import { createStore, useStore as baseUseStore, Store, Commit, Dispatch } from "vuex";
// contracts
import { nyxtContractInit } from "@/contracts/nyxt";
import { nyxgContractInit } from "@/contracts/nyxg";
import { nyxgrContractInit } from "@/contracts/nyxgr";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";

const ChainID = process.env.VUE_APP_NETWORK_ID
  ? process.env.VUE_APP_NETWORK_ID
  : "1";


// define your typings for the store state
export interface State {
  connected: boolean;
  wallet: string;
  nyxtBalance: number;
  nyxgBalance: number;
  pendingRewards: number;
  tokenIds: Array<any>;
  showMoons: boolean;
  baseUri: string;
  signer: any;
  nyxt: any;
  nyxg: any;
  nyxgr: any;
  provider: any;
  chain: number;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    connected: false,
    wallet: "",
    nyxtBalance: 0,
    nyxgBalance: 0,
    pendingRewards: 0,
    tokenIds: [],
    showMoons: false,
    baseUri: "",
    signer: null,
    nyxt: null,
    nyxg: null,
    nyxgr: null,
    provider: null,
    chain: 0
  },
  mutations: {
    setConnected(state, value) {
      state.connected = value;
      localStorage.setItem("isConnected", `${state.connected}`);
    },
    setWalletAddress(state, value) {
      state.wallet = value;
    },
    setNYXTBalance(state, value) {
      state.nyxtBalance = Number(value);
    },
    setNYXGAmount(state, value) {
      state.nyxgBalance = Number(value);
    },
    setUserTokenIds(state, value) {
      state.tokenIds = value;
    },
    setPendingRewards(state, value) {
      state.pendingRewards = Number(value);
    },
    showMoons(state, value) {
      state.showMoons = value;
    },
    setBaseUri(state, value) {
      state.baseUri = value;
    },
    setSigner(state, value) {
      state.signer = value
    },
    setNYXT(state, value) {
      state.nyxt = value
    },
    setNYXG(state, value) {
      state.nyxg = value
    },
    setNYXGR(state, value) {
      state.nyxgr = value
    },
    setProvider(state, value) {
      state.provider = value
    },
    setChain(state, value) {
      state.chain = value
    }
  },
  getters: {
    getConnected(state) {
      return state.connected;
    },
    getWallet(state) {
      return state.wallet;
    },
    getNYXTBalance(state) {
      return state.nyxtBalance;
    },
    getNYXGBalance(state) {
      return state.nyxgBalance;
    },
    getTokenIds(state) {
      return state.tokenIds;
    },
    getPendingRewards(state) {
      return state.pendingRewards;
    },
    getShowMoons(state) {
      return state.showMoons;
    },
    getBaseUri(state) {
      return state.baseUri;
    },
    getChain(state) {
      return state.chain
    },
    getNYXGR(state) {
      return state.nyxgr
    },
    getNYXG(state) {
      return state.nyxg
    }
  },
  actions: {
    async connectWeb3 ({
      commit,
      dispatch
    } : {
      commit: Commit;
      dispatch: Dispatch
    }) {
      if (store.state.connected == true && store.state.signer) return;

      let provider: any = await detectEthereumProvider();
      
      provider = new ethers.providers.Web3Provider(provider);

      if (provider) {
        const accounts = await (window as any).ethereum.request({
          method: "eth_requestAccounts",
        });
        commit("setWalletAddress", accounts[0]);
        commit("setConnected", true);
        const signer = provider.getSigner(store.state.wallet);
        commit("setSigner", markRaw(signer))
        commit("setProvider", markRaw(provider))
        await dispatch('checkNetwork');
        await dispatch('initializeContracts')
        await dispatch('ethereumListener')
        commit('setChain', Number(ChainID))
      }
    },
    async checkNetwork() {
      if ((window as any).ethereum) {
        const hexadecimal = "0x" + parseInt(ChainID).toString(16);
  
        try {
          // check if the chain to connect to is installed
          await (window as any).ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: hexadecimal }], // chainId must be in hexadecimal numbers
          });
        } catch (error: any) {
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902) {
            try {
              await (window as any).ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: hexadecimal,
                    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
          console.error(error);
        }
      }
    },
    async ethereumListener({ commit, dispatch }: { commit: Function, dispatch: Dispatch }) {
      (window as any).ethereum.on("accountsChanged", async (accounts: any) => {
        commit("setWalletAddress", accounts[0]);
        const provider = store.state.provider
        const signer = provider.getSigner(store.state.wallet);
        commit("setSigner", markRaw(signer))
        await dispatch('initializeContracts')
      });
      (window as any).ethereum.on('chainChanged', (_chainId: any) => {
        if (_chainId !== process.env.VUE_APP_NETWORK_ID_HEX) commit('setChain', 0)
        else commit('setChain', Number(ChainID))
        window.location.reload()
      });
      /*
      (window as any).ethereum.on("chainChanged", async (chainId: any) => {
        if (chainId !== process.env.VUE_APP_NETWORK_ID_HEX) {
          await dispatch('checkNetwork');
          await dispatch('initializeContracts')
          commit('setChain', 1)
        }
      });
      */
  
      (window as any).ethereum.on('disconnect', async (error: any) => {
        try {
          // remove the connection state from localstorage
          localStorage.removeItem("isConnected");
  
          // reload page
          window.location.reload();
        } catch (e) {
          console.error("error: ", error.message);
        }
      });
    },
    async initializeContracts({ commit }: { commit: Commit }) {
      const signer = store.state.signer
      if (!signer) return
      const nyxt = nyxtContractInit(signer, Number(ChainID));
      const nyxg = nyxgContractInit(signer, Number(ChainID));
      const nyxgr = nyxgrContractInit(signer, Number(ChainID));

      commit("setNYXT", markRaw(nyxt))
      commit("setNYXG", markRaw(nyxg))
      commit("setNYXGR", markRaw(nyxgr))

    },
    async fetchNYXTBalance({ commit }: { commit: Commit }) {
      if (store.state.chain == Number(ChainID)) {
        const nyxt = store.state.nyxt
        let nyxtBalance = await nyxt.balanceOf(store.state.wallet);
        nyxtBalance = Number(ethers.utils.formatUnits(nyxtBalance._hex, 9)).toFixed(
          0
        );
        store.commit("setNYXTBalance", nyxtBalance);
      }
    },
    async fetchNYXGBalance({ commit }: { commit: Commit }) {
      if (store.state.chain == Number(ChainID)) {
        const nyxg = store.state.nyxg
        let nyxgBalance = await nyxg.balanceOf(store.state.wallet);
        nyxgBalance = Number(ethers.utils.formatUnits(nyxgBalance._hex, 0));
        store.commit("setNYXGAmount", nyxgBalance);
      }
    },
    async fetchPendingRewards({ commit }: { commit: Commit }) {
      if (store.state.chain == Number(ChainID)) {
        // get overall pending balance
        const nyxgr = store.state.nyxgr
        try {
          let pendingRewards = await nyxgr.pendingRewards(
            ethers.utils.getAddress(store.state.wallet)
          );
          pendingRewards = Number(ethers.utils.formatUnits(pendingRewards._hex, 9));
          if (pendingRewards !== store.state.pendingRewards)
            store.commit("setPendingRewards", pendingRewards);
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
});

export function useStore() {
  return baseUseStore(key);
}
