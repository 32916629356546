import { ethers } from "ethers";
//@ts-ignore
import abi from "./abi.json";

const nyxgDeployments = {
  1: "0x20416233EBbdfc38a8a2d05329909e6cf37c81C7",
  42: "0x19C97d29835C1FEacf0aB955759359A7043ba4C9"
};

export const nyxgContractInit = (provider: any, chain: number) => {
  // @ts-ignore
  const address = nyxgDeployments[chain]; // refactor
  return new ethers.Contract(address, abi, provider);
};
